import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ImprintCard from "../../components/ImprintCard";

class ViewImprintScreen extends Component {
  render() {
    return (
      <div className="content">
        <ImprintCard title={"Impressum"} />
      </div>
    );
  }
}

export default withRouter(ViewImprintScreen);
