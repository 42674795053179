import React, { Component } from "react";
import Input from "../../atoms/Input";
import FinalDialog from "../../components/FinalDialog";
import { withRouter } from "react-router-dom";

import SaveButton from "../../atoms/SaveButton";
import MaterialDialog from "../../components/MaterialDialog";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import MediaCard from "../../components/MediaCard";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MobileStepper from "../../components/MobileStepper";
import ErrorDialog from "../../components/ErrorDialog";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ViewCalculatorScreen extends Component {
  state = {
    stonelength: "",
    stonewidth: "",
    jointwidth: "",
    jointdepth: "",
    size: "",
    material: "",
    result: "",
    open: false,

    oneBucketSmall: "",
    oneBucketBig: "",
    kiloPerUnitSmall: "",
    kiloPerUnitBig: "",
    materialPerQm: "",
    totalKiloPer15Kilo: "",
    totalKiloPer25Kilo: "",
    totalKiloPer20Kilo: "",
    errorState: false,
    errorMessage: "Das ist ein Error",
    errors: {},
    product: {
      title: "",
      image: "",
      link: "",
      id: "",
      densityValue: "",
      jointWidth: ""
    },
    shopLink: "https://galapower.de"
  };

  finalDialog = () => {
    return <FinalDialog />;
  };

  checkJointWidth = (value, minVal) => parseInt(value, 10) < parseInt(minVal, 10);

  onCalculate = () => {
    if (this.state.material === "") {
      return this.setState({
        errorMessage: "Bitte wählen Sie ein Material aus",
        errorState: true
      });
    }

    if (
      this.state.stonelength === "" ||
      this.state.stonewidth === "" ||
      this.state.jointwidth === "" ||
      this.state.jointdepth === "" ||
      this.state.size === ""
    ) {
      return this.setState({
        errorMessage: "Bitte füllen Sie alle Felder aus",
        errorState: true
      });
    }

    if (this.checkJointWidth(this.state.jointwidth, this.state.product.jointWidth)) {
        return this.setState({
            errorMessage: "Erforderliche Fugenbreite: " + this.state.product.jointWidth + " mm",
            errorState: true
        });
    }

    //Abschnittslänge längs
    let F =
      parseFloat(this.state.stonelength) +
      parseFloat(this.state.jointwidth) / 10;

    //Abschnittslänge quer
    let G =
      parseFloat(this.state.stonewidth) +
      parseFloat(this.state.jointwidth) / 10;

    //Anzahl der Fugen pro Meter längs
    let H = 100 / F;

    //Anzahl der Fugen pro Meter quer
    let I = 100 / G;

    //Summe der Fugenbreiten cm/m
    let J = H * (parseFloat(this.state.jointwidth) / 10);
    let K = I * (parseFloat(this.state.jointwidth) / 10);
    let L =
      (parseFloat(this.state.jointdepth) * J * 100 +
        parseFloat(this.state.jointdepth) * K * 100) /
      1000; //Fugenvol/m²

    let M = L * parseFloat(this.state.material);

    //Materialverbrauch kg/m^2
    let matPerQm = M / 10;

    const result = this.totalKilo(parseFloat(this.state.size), matPerQm);

    this.setState({
      result: this.nextNumberRoundFunc(result), //Gesamtbedarf für die Fläche
      materialPerQm: this.roundFunc(matPerQm), // Bedarf des Materials Pro Quadratmeter
      totalKiloPer15Kilo: this.nextNumberRoundFunc(result / 15), // Die Anzahl an Eimer in 15 kg
      totalKiloPer25Kilo: this.nextNumberRoundFunc(result / 25), // Die Anzahl an Eimer in 25 kg
      totalKiloPer20Kilo: this.nextNumberRoundFunc(result / 20) // Die Anzahl an Eimer in 20 kg
    });

    // Ergebnis ca in kg/m²

    this.handleDialog();
    //gesamtbedarf/ fläche
  };

  roundFunc = value => {
    value *= 100;
    value = Math.round(value);
    return (value /= 100);
  };

  nextNumberRoundFunc = value => {
    value *= 100;
    value = Math.round(value);
    return Math.ceil((value /= 100));
  };

  totalKilo = (size, material) => {
    return size * material;
  };

  oneBucket = (size, mainKilo, containerSize = 15) =>
    this.roundFunc((size / mainKilo) * containerSize);

  kiloPerUnit = (result, containerSize) => {
    var kilpE = ((result / containerSize) * 10) % 10;
    if (kilpE > 0) {
      return Math.trunc(kilpE) + 1;
    } else {
      return Math.trunc(kilpE);
    }
  };

  handleDialog = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      stonelength,
      stonewidth,
      jointwidth,
      jointdepth,
      size,
      result,
      materialPerQm,
      totalKiloPer15Kilo,
      totalKiloPer25Kilo,
      totalKiloPer20Kilo,
      errorState,
      errorMessage,
        product,
        shopLink
    } = this.state;

    const {products, apiUrl, banner} = this.props;

    return (
      <div className="content normalize">
        <div className="columns">
          <div className="with-headline">
            <h2 className={'sub-title'}>Unsere Produkte</h2>
            <div className="kasten">
              <MobileStepper
                  banner={banner}
                  apiUrl={apiUrl}
              />
            </div>
          </div>
        </div>
        <div className="columns columns--small">
          <div className="with-headline">
            <h2 className={'sub-title'}>Bedarfsrechner</h2>
            <MaterialDialog
                onChange={(value, product) => this.setState({material: value, product: product })}
                products={products}
                apiUrl={apiUrl}
            />
          </div>
        </div>

        <div className="columns">
          <div className="columns__column">
            <Input
              placeholder="in cm"
              label="Steinlänge [cm]"
              value={stonelength}
              onChange={e => this.setState({ stonelength: e.target.value })}
            />
          </div>
          <div className="columns__column">
            <Input
              placeholder="in cm"
              label="Steinbreite [cm]"
              value={stonewidth}
              onChange={e => this.setState({ stonewidth: e.target.value })}
            />
          </div>
        </div>

        <div className="columns">
          <div className="columns__column">
            <Input
              placeholder="in mm"
              label="Fugenbreite [mm]"
              value={jointwidth}
              onChange={e => this.setState({ jointwidth: e.target.value })}
            />
          </div>
          <div className="columns__column">
            <Input
              placeholder="in mm"
              label="Fugentiefe [mm]"
              value={jointdepth}
              onChange={e => this.setState({ jointdepth: e.target.value })}
            />
          </div>
        </div>

        <div className="columns">
          <div className="columns__column">
            <Input
              placeholder="in m&sup2;"
              label="Fläche [m&sup2;]"
              value={size}
              onChange={e => this.setState({ size: e.target.value })}
            />
          </div>
          <div className="columns__column">
              <SaveButton text="los" onClick={this.onCalculate} label={'berechnen'} />

            <ErrorDialog
              message={errorMessage}
              isOpen={errorState}
              handleClick={() => {
                this.setState({ errorState: true });
              }}
              handleClose={() => {
                this.setState({ errorState: false });
              }}
            />

            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              fullWidth
            >
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
                id={"close-button"}
              >
                <CloseIcon />
              </IconButton>

              <MediaCard
                title={product && product.title}
                result={result}
                materialPerQm={materialPerQm}
                totalKiloPer15Kilo={totalKiloPer15Kilo}
                totalKiloPer25Kilo={totalKiloPer25Kilo}
                totalKiloPer20Kilo={totalKiloPer20Kilo}
                image={product && apiUrl + 'images/' + product.imageUrl}
                shopLink={shopLink}
                productLink={product && product.webLink}

              />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ViewCalculatorScreen);
