import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    marginBottom: 16,
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

function ImprintCard(props) {
  const { classes, title } = props;

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            align={"center"}
          >
            {title}
          </Typography>
          <Typography component="p" align={"center"}>
            GalaPower.de <br/>
            ist ein Onlineauftritt der Firma <br/>
            Multiflex Vertriebs GmbH <br/> <br/>

            Siemensstr. 10 <br />
            40885 Ratingen <br />
            <br />
            Tel. 02102 148 40 23 <br />
            Mail info@multiflex-gmbh.de <br />
            <br />
            Geschäftsführer <br />
            Dr.-Ing. Michael Kaffka <br />
            <br />
            UST-ID: DE 815296 040 <br />
            HRB 72818 Amtsgericht Düsseldorf <br />
            <br />
            Entwicklung/Umsetzung <br />
            Christian Egemann <br />
            Mail christian@software-ninja.de <br />
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            href={"https://multiflex-shop.de/datenschutzerklaerung"}
          >
            Datenschutzerklärung
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

ImprintCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default withStyles(styles)(ImprintCard);
