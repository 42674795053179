import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import ProductCard from "../../components/ProductCard";

class ViewProductScreen extends Component {
  render() {
    const products = [
      {
        title: "TerraFLEX 1k Easy",
        image: "/images/Terraflex-easy-VR.jpg",
        description1: "> Fugen ab 4mm",
        description2: "> Personenbelastung",
        description3: "> einschlämmbar",
        link: "https://multiflex-shop.de/",
        key: "1"
      },
      {
        title: "TerraFLEX 1k Easy fein",
        image: "/images/Terraflex-easy-fein-VR.jpg",
        description1: "> Fugen ab 2mm",
        description2: "> PKW befahrbar",
        description3: "> für Keramik",
        link: "https://multiflex-shop.de/",
        key: "2"
      },
      {
        title: "TerraFLEX 1k Premium fein",
        image: "/images/Terraflex-easy-VR.jpg",
        description1: "> Fugen ab 2mm",
        description2: "> für Keramik",
        description3: "> hohe Festigkeit",
        link: "https://multiflex-shop.de/",
        key: "3"
      },
      {
        title: "TerraFLEX 2k 10",
        image: "/images/HadaFLEX-2k-10-VR.jpg",
        description1: "> Fugen ab 4mm",
        description2: "> PKW befahrbar",
        description3: "> hohe Festigkeit",
        link: "https://multiflex-shop.de/",
        key: "4"
      },
      {
        title: "TerraFLEX 2k Easy 15",
        image: "/images/Hadaflex_2k-easy-alle-VR.jpg",
        description1: "> Fugen ab 5mm",
        description2: "> einschlämmbar",
        description3: "> PKW befahrbar",
        link: "https://multiflex-shop.de/",
        key: "5"
      },
      {
        title: "TerraFLEX 2k Easy 25",
        image: "/images/Hadaflex_2k-easy-alle-VR.jpg",
        description1: "> Fugen ab 6mm",
        description2: "> einschlämmbar",
        description3: "> Zuliefererverkehr",
        link: "https://multiflex-shop.de/",
        key: "6"
      },
      {
        title: "TerraFLEX 2k Easy 40",
        image: "/images/Hadaflex_2k-easy-alle-VR.jpg",
        description1: "> Fugen ab 8mm",
        description2: "> einschlämmbar",
        description3: "> LKW belastbar",
        link: "https://multiflex-shop.de/",
        key: "7"
      }
    ];

    return (
      <div className="content">
        {/*<ProductCard product={product}/>*/}
        {products.map(product => (
          <div className={"margin-top"} key={product.key}>
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(ViewProductScreen);
