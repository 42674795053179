import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from 'react-swipeable-views-utils';

import Loading from '../Loading';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    borderRadius: 3
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    marginBottom: 20,
    backgroundColor: theme.palette.background.default
  },
  img: {
    height: 150,
    maxWidth: 400,
    overflow: "hidden",
    width: "100%",
    borderRadius: 3
  },
  stepperNavigation: {
    display: "flex",
    justifyContent: "space-between"
  }
});

class SwipeableTextMobileStepper extends React.Component {
  state = {
    activeStep: 0
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  render() {
    const { classes, theme, banner, apiUrl } = this.props;

    return (
      <div className={classes.root} >
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={this.state.activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
          interval={6000}
        >
            {banner.length > 1 ? banner.map(step => (
                <a key={step.id} href={step.webLink}>
                    <img
                        className={classes.img}
                        src={apiUrl + step.imageUrl}
                        alt={step.label}
                    />
                </a>

            )) : <Loading />}
        </AutoPlaySwipeableViews>
        {/*<div className={classes.stepperNavigation}>*/}
        {/*  <Button size="small" onClick={this.handleBack} disabled={activeStep === 0} style={{color:"#fff"}}>*/}
        {/*    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}*/}
        {/*    zurück*/}
        {/*  </Button>*/}
        {/*  <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1} style={{color:"#fff"}}>*/}
        {/*    weiter*/}
        {/*    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    );
  }
}

SwipeableTextMobileStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  SwipeableTextMobileStepper
);
